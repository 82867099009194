// import React, { useState, useEffect } from "react";
// import axios from "axios";

// interface WithdrawItem {
//   transaction_id: number;
//   uid: string;
//   date: string;
//   wallet: string;
//   amount: number;
//   name: string;
//   amount_usdt: number;
//   status: string;
// }

// const Withdraw: React.FC = () => {
//   const [withdrawData, setWithdrawData] = useState<WithdrawItem[]>([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     fetchWithdrawData();
//   }, []);

//   const fetchWithdrawData = async () => {
//     try {
//       const response = await axios.get('https://api.time2win.in/api/withdrawals');
//       const data = response.data;

//       if (Array.isArray(data)) {
//         const reversedData = data.reverse();
//         setWithdrawData(reversedData);
//       } else {
//         console.error('Unexpected response structure:', data);
//         setWithdrawData([]);
//       }
//     } catch (error) {
//       console.error('Failed to fetch withdrawal data:', error);
//       setWithdrawData([]);
//     }
//   };

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };

//   const handleUpdateStatus = async (transaction_id: number, status: string) => {
//     try {
//       await axios.put('https://api.time2win.in/api/withdraw/status', { transaction_id, status });
//       fetchWithdrawData();
//     } catch (error) {
//       console.error(`Failed to update withdrawal status for transaction_id ${transaction_id}:`, error);
//     }
//   };

//   const filteredWithdrawData = searchTerm
//     ? withdrawData.filter((withdraw) => 
//         String(withdraw.uid).includes(searchTerm) || 
//         withdraw.name.toLowerCase().includes(searchTerm.toLowerCase()))
//     : withdrawData;

//   return (
//     <div>
//       <div className="mb-4">
//         <input
//           type="text"
//           placeholder="Search by name or UID"
//           value={searchTerm}
//           onChange={handleSearchChange}
//           className="p-2 border rounded w-full"
//         />
//       </div>
//       <div className="h-[690px] overflow-scroll">
//         <table className="min-w-full">
//           <thead>
//             <tr>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Wallet Address</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount (USDT)</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
//               <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredWithdrawData.map((withdraw, index) => (
//               <tr key={withdraw.transaction_id}>
//                 <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.uid}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.name}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.date}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.amount}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.wallet}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.amount_usdt.toFixed(2)}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">{withdraw.status}</td>
//                 <td className="py-3 px-4 border-b border-gray-200">
//                   {withdraw.status === 'pending' && (
//                     <>
//                       <button
//                         onClick={() => handleUpdateStatus(withdraw.transaction_id, 'accepted')}
//                         className="text-white bg-green-500 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
//                       >
//                         Accept
//                       </button>
//                       <button
//                         onClick={() => handleUpdateStatus(withdraw.transaction_id, 'rejected')}
//                         className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
//                       >
//                         Reject
//                       </button>
//                     </>
//                   )}
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Withdraw;
import React, { useState, useEffect } from "react";
import axios from "axios";

interface WithdrawItem {
  transaction_id: number;
  uid: string;
  amount: number;
  date: string; // Timestamp as date
  status: string;
  wallet: string; // UPI ID as wallet
  amount_usdt: number;
  username: string; // User's name from the user table
}

const Withdraw: React.FC = () => {
  const [withdrawData, setWithdrawData] = useState<WithdrawItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchWithdrawData();
  }, []);

  const fetchWithdrawData = async () => {
    try {
      const response = await axios.get('https://api.time2win.in/api/withdrawals');
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setWithdrawData(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setWithdrawData([]);
      }
    } catch (error) {
      console.error('Failed to fetch withdrawal data:', error);
      setWithdrawData([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleUpdateStatus = async (transaction_id: number, status: string) => {
    try {
      await axios.put('https://api.time2win.in/api/withdraw/status', { transaction_id, status });
      fetchWithdrawData();
    } catch (error) {
      console.error(`Failed to update withdrawal status for transaction_id ${transaction_id}:`, error);
    }
  };

  const filteredWithdrawData = searchTerm
    ? withdrawData.filter((withdraw) => 
        String(withdraw.uid).includes(searchTerm) || 
        withdraw.username.toLowerCase().includes(searchTerm.toLowerCase()))
    : withdrawData;

  return (
    <div>
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by username or UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
      </div>
      <div className="h-[690px] overflow-scroll">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Username</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UPI Address</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="py-3 px-4 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredWithdrawData.map((withdraw, index) => (
              <tr key={withdraw.transaction_id}>
                <td className="py-3 px-4 border-b border-gray-200">{index + 1}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.uid}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.username}</td>
                <td className="py-3 px-4 border-b border-gray-200">{new Date(withdraw.date).toLocaleDateString()}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.wallet}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.amount}</td>
                <td className="py-3 px-4 border-b border-gray-200">{withdraw.status}</td>
                <td className="py-3 px-4 border-b border-gray-200">
                  {withdraw.status === 'pending' && (
                    <>
                      <button
                        onClick={() => handleUpdateStatus(withdraw.transaction_id, 'accepted')}
                        className="text-white bg-green-500 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleUpdateStatus(withdraw.transaction_id, 'rejected')}
                        className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdraw;
