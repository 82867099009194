
import axios from 'axios';
import React, { useState, useEffect } from 'react';

interface User {
  uid: string;
  name: string;
  balance: number;
}

const Wallet: React.FC = () => {
  const [transferDetails, setTransferDetails] = useState<User[]>([]);
  const [debitValues, setDebitValues] = useState<Record<string, string>>({});
  const [creditValues, setCreditValues] = useState<Record<string, string>>({});
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchTransferDetails();
  }, []);

  const fetchTransferDetails = async () => {
    try {
      const response = await fetch(`https://api.time2win.in/api/users/getwallet`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setTransferDetails(data);
    } catch (error) {
      console.error(`Error fetching transfer details:`, error);
      setTransferDetails([]);
    }
  };

  const handleDebit = async (uid: string, debitAmount: string) => {
    const user = transferDetails.find((u) => u.uid === uid);
    if (!user) {
      console.error('User not found');
      return;
    }
    const currentBalance = parseFloat(user.balance.toString()); // Parse balance as float
    const amountToDebit = parseFloat(debitAmount);
    const newBalance = Math.max(0, currentBalance - amountToDebit);
  
    try {
      const response = await axios.post('https://api.time2win.in/api/users/updatebalance', {
        uid,
        amount: newBalance,
      });
  
      const updatedTransferDetails = transferDetails.map((user) =>
        user.uid === uid ? { ...user, balance: newBalance } : user
      );
      setTransferDetails(updatedTransferDetails);
  
      setDebitValues((prevValues) => ({
        ...prevValues,
        [uid]: '',
      }));
    } catch (error) {
      console.error('Error updating wallet:', error);
    }
  };
  
  const handleCredit = async (uid: string, creditAmount: string) => {
    const user = transferDetails.find((u) => u.uid === uid);
    if (!user) {
      console.error('User not found');
      return;
    }
    const currentBalance = parseFloat(user.balance.toString()); // Parse balance as float
    const amountToCredit = parseFloat(creditAmount);
    const newBalance = currentBalance + amountToCredit;
  
    try {
      const response = await axios.post('https://api.time2win.in/api/users/updatebalance', {
        uid,
        amount: newBalance,
      });
  
      const updatedTransferDetails = transferDetails.map((user) =>
        user.uid === uid ? { ...user, balance: newBalance } : user
      );
      setTransferDetails(updatedTransferDetails);
  
      setCreditValues((prevValues) => ({
        ...prevValues,
        [uid]: '',
      }));
    } catch (error) {
      console.error('Error updating wallet:', error);
    }
  };
  

  const handleInputChange = (uid: string, value: string, type: 'debit' | 'credit') => {
    if (type === 'debit') {
      setDebitValues((prevValues) => ({
        ...prevValues,
        [uid]: value,
      }));
    } else if (type === 'credit') {
      setCreditValues((prevValues) => ({
        ...prevValues,
        [uid]: value,
      }));
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredUsers = transferDetails.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.uid.toString().includes(searchTerm)
  );

  return (
    <div className="p-2">
      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name or UID"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border rounded w-full"
        />
      </div>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">
                S.No
              </th>
              <th scope="col" className="py-3 px-6">
                Name
              </th>
              <th scope="col" className="py-3 px-6">
                UID
              </th>
              <th scope="col" className="py-3 px-6">
                Balance
              </th>
              <th scope="col" className="py-3 px-6">
                Update Balance
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.uid} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{index + 1}</td>
                <td className="py-4 px-6">{user.name}</td>
                <td className="py-4 px-6">{user.uid}</td>
                <td>{user.balance}</td>
                <td className="py-4 px-6 flex items-center">
                  <input
                    type="text"
                    value={String(debitValues[user.uid] || '')}
                    onChange={(e) => handleInputChange(user.uid, e.target.value, 'debit')}
                    style={{
                      marginRight: '8px',
                      border: '1px solid #ccc',
                    }}
                    className="text-field-class"
                  />
                  <button
                    onClick={() => handleDebit(user.uid, debitValues[user.uid] || '0')}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      border: 'none',
                    }}
                    className="update-button-class"
                  >
                    Debit
                  </button>
                </td>
                <td className="py-2 px-6 flex items-center">
                  <input
                    type="text"
                    value={String(creditValues[user.uid] || '')}
                    onChange={(e) => handleInputChange(user.uid, e.target.value, 'credit')}
                    style={{
                      marginRight: '8px',
                      border: '1px solid #ccc',
                    }}
                    className="text-field-class"
                  />
                  <button
                    onClick={() => handleCredit(user.uid, creditValues[user.uid] || '0')}
                    style={{
                      backgroundColor: 'green',
                      color: 'white',
                      padding: '8px 16px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      border: 'none',
                    }}
                    className="update-button-class"
                  >
                    Credit
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Wallet;
