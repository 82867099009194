
import React, { useState, useEffect } from 'react';
import Tooltip from './Tooltip';

interface User {
  uid: string;
  name: string;
  email: string;
}

interface DepositTransaction {
  uid: string;
  date: string;
  txtid: string;
  amount: number;
   type: 'deposit';
}

interface WithdrawalTransaction {
  uid: string;
  date: string;
  wallet: string;
  amount: number;
  type: 'withdrawal';
}

interface LiveBet {
  game: string;
  uid: string;
  amount: number;
  sessionId: string;
  name: string;
}

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [todayDeposits, setTodayDeposits] = useState(0);
  const [todayWithdrawals, setTodayWithdrawals] = useState(0);
  const [liveBets, setLiveBets] = useState<LiveBet[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [transactions, setTransactions] = useState<(DepositTransaction | WithdrawalTransaction)[]>([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Fetch users data
        const userResponse = await fetch('https://api.time2win.in/api/users');
        const userData = await userResponse.json();
        setUsers(userData);
        setUserCount(userData.length);

        // Fetch summary data (total deposits and withdrawals)
        const summaryResponse = await fetch('https://api.time2win.in/api/summary');
        const summaryData = await summaryResponse.json();
        setTotalDeposits(summaryData.total_deposits);
        setTotalWithdrawals(summaryData.total_withdrawals);
      } catch (error) {
        console.error('Failed to fetch user data or summary:', error);
      }
    };

    const fetchTodayDepositsAndWithdrawals = async () => {
      try {
        const today = new Date().toISOString().split('T')[0];

        // Fetch today's deposits
        const depositResponse = await fetch('https://api.time2win.in/api/deposits');
        const depositData = await depositResponse.json();
        const totalDepositAmountToday = depositData.reduce((total: number, deposit: DepositTransaction) => {
          const depositDate = deposit.date.split('T')[0];
          return depositDate === today ? total + deposit.amount : total;
        }, 0);
        setTodayDeposits(totalDepositAmountToday);

        // Fetch today's withdrawals
        const withdrawResponse = await fetch('https://api.time2win.in/api/withdrawals');
        const withdrawData = await withdrawResponse.json();
        const totalWithdrawalAmountToday = withdrawData.reduce((total: number, withdrawal: WithdrawalTransaction) => {
          const withdrawDate = withdrawal.date.split('T')[0];
          return withdrawDate === today ? total + withdrawal.amount : total;
        }, 0);
        setTodayWithdrawals(totalWithdrawalAmountToday);
      } catch (error) {
        console.error('Failed to fetch deposit or withdrawal data:', error);
      }
    };

    fetchUserData();
    fetchTodayDepositsAndWithdrawals();
  }, []);

  const fetchLiveBets = async () => {
    try {
      const response = await fetch('https://api.time2win.in/api/bets');
      const data: LiveBet[] = await response.json();
      setLiveBets(data);
    } catch (error) {
      console.error('Failed to fetch live bets:', error);
    }
  };

  useEffect(() => {
    fetchLiveBets();
    const interval = setInterval(fetchLiveBets, 10000);
    return () => clearInterval(interval);
  }, []);

  const fetchTransactions = async (type: 'deposit' | 'withdrawal') => {
    try {
      const response = await fetch(type === 'deposit' ? 'https://api.time2win.in/api/deposits' : 'https://api.time2win.in/api/withdrawals');
      const data: DepositTransaction[] | WithdrawalTransaction[] = await response.json();
      setTransactions(data);
      setShowModal(true);
    } catch (error) {
      console.error(`Failed to fetch ${type} transactions:`, error);
    }
  };

  return (
    <div>
      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4 text-white mb-10'>
        <div className='bg-sky-500 shadow rounded-lg p-4'>
          <span className='text-white'>Total Users</span>
          <div className='text-xl font-bold'>{userCount}</div>
        </div>
        <div className='bg-green-500 shadow rounded-lg p-4'>
          <span className='text-white'>Total Deposits</span>
          <div className='text-xl font-bold'>₹{totalDeposits}</div>
        </div>
        <div className='bg-red-500 shadow rounded-lg p-4'>
          <span className='text-white'>Total Withdrawals</span>
          <div className='text-xl font-bold'>₹{totalWithdrawals}</div>
        </div>
        <div className='bg-yellow-500 shadow rounded-lg p-4'>
          <span className='text-white'>Live Users</span>
          <div className='text-xl font-bold'>0</div>
        </div>
        <div className='bg-green-800 shadow rounded-lg p-4 flex justify-center items-center'>
          <Tooltip message="Tap to show today's deposit" onClick={() => fetchTransactions('deposit')}>
            <span className='text-white'>Today's Deposit</span>
          </Tooltip>
        </div>
        <div className='bg-pink-700 shadow rounded-lg p-4 flex justify-center items-center'>
          <Tooltip message="Tap to show today's withdrawal" onClick={() => fetchTransactions('withdrawal')}>
            <span className='text-white'>Today's Withdrawal</span>
          </Tooltip>
        </div>
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg max-w-sm w-full p-5 relative">
              <h2 className="text-lg font-bold text-black m-0">Today's Transactions</h2>
              <button onClick={() => setShowModal(false)} className="absolute top-2 right-2 text-2xl text-gray-600 hover:text-gray-800">
                &times;
              </button>
              {transactions.length > 0 ? (
                <div className="mt-4">
                  <table className="w-full">
                    <thead>
                      <tr>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">UID</th>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">Date</th>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">Amount</th>
                        <th className="border-b-2 border-gray-200 p-2 text-left text-black">Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                          <td className="p-2 text-black">{transaction.uid}</td>
                          <td className="p-2 text-black">{new Date(transaction.date).toLocaleDateString()}</td>
                          <td className="p-2 text-black">{transaction.amount}</td>
                          <td className="p-2 text-black">{transaction.type ? 'Deposit' : 'Withdrawal'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="mt-4 text-gray-600">No transactions available.</div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* <div className='flex justify-center items-start h-screen'>
        <div className='w-[800px] h-[450px] overflow-auto relative rounded-lg'>
          <table className="text-sm text-left text-gray-500 w-full">
            <thead className="text-m text-white uppercase bg-[#161b2b] sticky top-0 z-10">
              <tr>
                <th scope="col" className="py-3 px-6">Live Bets</th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
              </tr>
            </thead>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50  top-[3rem] z-10">
              <tr>
                <th scope="col" className="py-3 px-6">S.No</th>
                <th scope="col" className="py-3 px-6">Name</th>
                <th scope="col" className="py-3 px-6">Uid</th>
                <th scope="col" className="py-3 px-6">Game</th>
                <th scope="col" className="py-3 px-6">Amount</th>
                <th scope="col" className="py-3 px-6">Session Id</th>
              </tr>
            </thead>
            <tbody className="h-[600px] overflow-auto block max-w-[450px] z-10">
              {liveBets.map((bet, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                  <td className="p-2">{index + 1}</td>
                  <td className="p-2">{bet.name}</td>
                  <td className="p-2">{bet.uid}</td>
                  <td className="p-2">{bet.game}</td>
                  <td className="p-2">{bet.amount}</td>
                  <td className="p-2">{bet.sessionId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> */}
      <div className='flex justify-center items-start h-screen'>
  <div className='w-3/4 h-3/4 overflow-auto relative rounded-lg'>
    <div className="text-xl font-bold mb-4">Live Bets</div>
    <table className="text-sm text-gray-500 w-full border-collapse border">
      <thead className="text-xs text-white uppercase bg-[#161b2b] sticky top-0">
        <tr>
          <th scope="col" className="py-3 px-6 text-left">S.No</th>
          <th scope="col" className="py-3 px-6 text-left">Name</th>
          <th scope="col" className="py-3 px-6 text-left">Uid</th>
          <th scope="col" className="py-3 px-6 text-left">Game</th>
          <th scope="col" className="py-3 px-6 text-left">Amount</th>
          <th scope="col" className="py-3 px-6 text-left">Session Id</th>
        </tr>
      </thead>
      <tbody className="text-gray-700">
        {liveBets.map((bet, index) => (
          <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
            <td className="py-3 px-6 border">{index + 1}</td>
            <td className="py-3 px-6 border">{bet.name}</td>
            <td className="py-3 px-6 border">{bet.uid}</td>
            <td className="py-3 px-6 border">{bet.game}</td>
            <td className="py-3 px-6 border">{bet.amount}</td>
            <td className="py-3 px-6 border">{bet.sessionId}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>

    </div>
  );
};

export default AdminDashboard;
