import React from 'react';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Users from './components/Users';
import Settings from './components/Settings';
import AdminDashboard from './components/AdminDashboard';
import LoginPage from './components/Login';
import Wallet from './components/WalletUpdate';
import Deposit from './components/Deposit';
import Withdraw from './components/Withdraw';
import House from './components/House'
import History from './components/History';
import ReferralRewards from './components/ReferralRewards';
import UserReferralCodes from './components/UserReferralCodes';
import AdminUpiComponent from './components/AdminUpiComponent';

function App() {
  const token = localStorage.getItem('token');

  const ProtectedRoutes = () => {
    return token ? <Outlet /> : <Navigate to="/login" replace />;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/" element={<Layout />}>
            <Route index element={<AdminDashboard />} />
            <Route path="withdraw" element={<Withdraw />} />
            <Route path="users" element={<Users />} />
            <Route path="settings" element={<Settings />} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="house" element={<House />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="history" element={<History />} />
            <Route path="referralRewards" element={<ReferralRewards />} />
            <Route path="userReferral" element={<UserReferralCodes />} />
            <Route path="upi" element={<AdminUpiComponent />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
