
// import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// type User = {
//   id: string;
//   name: string;
//   email: string;
//   total_deposit_usdt: number;
//   total_withdrawal_usdt: number;
// };

// const Users: React.FC = () => {
//   const [users, setUsers] = useState<User[]>([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [snackbar, setSnackbar] = useState<string | null>(null);

//   useEffect(() => {
//     fetchUsersData();
//   }, []);

//   const fetchUsersData = async () => {
//     try {
//       const response = await axios.get('https://api.time2win.in/api/users');
//       const data = response.data;

//       if (Array.isArray(data)) {
//         const reversedData = data.reverse();
//         setUsers(reversedData);
//       } else {
//         console.error('Unexpected response structure:', data);
//         setUsers([]);
//       }
//     } catch (error) {
//       console.error('Failed to fetch user data:', error);
//       setUsers([]);
//     }
//   };

//   const handleDeleteUser = async (id: string, name: string) => {
//     try {
//       await axios.delete(`https://api.time2win.in/api/users/${id}`);
//       fetchUsersData();
//       setSnackbar(`User ${name} deleted successfully`);
//       setTimeout(() => setSnackbar(null), 3000); // Hide snackbar after 3 seconds
//     } catch (error) {
//       console.error('Failed to delete user:', error);
//     }
//   };

//   const filteredUsers = searchTerm
//     ? users.filter(user =>
//         user.id.toString().includes(searchTerm) ||
//         user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//         user.email.toLowerCase().includes(searchTerm.toLowerCase())
//       )
//     : users;

//   return (
//     <div className="p-2">
//       <div className="mb-4 flex justify-center mb-5">
//         <label htmlFor="search" className="sr-only">Search</label>
//         <div className="relative w-1/2">
//           <input
//             type="text"
//             id="search"
//             className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
//             placeholder="Search by email, name or UID"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//             <svg className="h-5 w-5 text-gray-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
//               <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
//             </svg>
//           </div>
//         </div>
//       </div>

//       {snackbar && (
//         <div className="mb-4 text-center text-white bg-green-500 py-2 px-4 rounded-lg shadow-md">
//           {snackbar}
//         </div>
//       )}

//       <h1 className="text-2xl font-semibold mb-4">{`Total Users: ${users.length}`}</h1>
//       <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
//         <table className="w-full text-sm text-left text-gray-500">
//           <thead className="text-xs text-gray-700 uppercase bg-gray-50">
//             <tr>
//               <th scope="col" className="py-3 px-6">S.No</th>
//               <th scope="col" className="py-3 px-6">Name</th>
//               <th scope="col" className="py-3 px-6">Email</th>
//               <th scope="col" className="py-3 px-6">UID</th>
//               <th scope="col" className="py-3 px-6">Total Deposit </th>
//               <th scope="col" className="py-3 px-6">Total Withdrawal</th>
//               <th scope="col" className="py-3 px-6">Action</th>
//             </tr>
//           </thead>
//           <tbody>
//             {filteredUsers.map((user, index) => (
//               <tr key={user.id} className="bg-white border-b hover:bg-gray-50">
//                 <td className="py-4 px-6">{index + 1}</td> {/* S.No column */}
//                 <td className="py-4 px-6">{user.name}</td>
//                 <td className="py-4 px-6">{user.email}</td>
//                 <td className="py-4 px-6">{user.id}</td>
//                 <td className="py-4 px-6 text-green-600">{`₹${user.total_deposit_usdt}`}</td>
//                 <td className="py-4 px-6 text-red-600">{`₹${user.total_withdrawal_usdt}`}</td>
//                 <td className="py-4 px-6">
//                   <button
//                     onClick={() => handleDeleteUser(user.id, user.name)}
//                     className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
//                   >
//                     Delete
//                   </button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Users;
import React, { useState, useEffect } from 'react';
import axios from 'axios';

type User = {
  id: string;
  name: string;
  email: string;
  total_deposit_usdt: number;
  total_withdrawal_usdt: number;
};

const Users: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [snackbar, setSnackbar] = useState<string | null>(null);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);

  useEffect(() => {
    fetchUsersData();
  }, []);

  const fetchUsersData = async () => {
    try {
      const response = await axios.get('https://api.time2win.in/api/users');
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setUsers(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setUsers([]);
      }
    } catch (error) {
      console.error('Failed to fetch user data:', error);
      setUsers([]);
    }
  };

  const handleDeleteUser = async (id: string, name: string) => {
    try {
      await axios.delete(`https://api.time2win.in/api/users/${id}`);
      fetchUsersData();
      setSnackbar(`User ${name} deleted successfully`);
      setTimeout(() => setSnackbar(null), 3000); // Hide snackbar after 3 seconds
      setUserToDelete(null);
    } catch (error) {
      console.error('Failed to delete user:', error);
    }
  };

  const confirmDeleteUser = (user: User) => {
    setUserToDelete(user);
  };

  const cancelDeleteUser = () => {
    setUserToDelete(null);
  };

  const filteredUsers = searchTerm
    ? users.filter(user =>
        user.id.toString().includes(searchTerm) ||
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : users;

  return (
    <div className="p-2">
      <div className="mb-4 flex justify-center mb-5">
        <label htmlFor="search" className="sr-only">Search</label>
        <div className="relative w-1/2">
          <input
            type="text"
            id="search"
            className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            placeholder="Search by email, name or UID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <svg className="h-5 w-5 text-gray-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
      </div>

      {snackbar && (
        <div className="mb-4 text-center text-white bg-green-500 py-2 px-4 rounded-lg shadow-md">
          {snackbar}
        </div>
      )}

      <h1 className="text-2xl font-semibold mb-4">{`Total Users: ${users.length}`}</h1>
      <div className="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="py-3 px-6">S.No</th>
              <th scope="col" className="py-3 px-6">Name</th>
              <th scope="col" className="py-3 px-6">Email</th>
              <th scope="col" className="py-3 px-6">UID</th>
              <th scope="col" className="py-3 px-6">Total Deposit </th>
              <th scope="col" className="py-3 px-6">Total Withdrawal</th>
              <th scope="col" className="py-3 px-6">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={user.id} className="bg-white border-b hover:bg-gray-50">
                <td className="py-4 px-6">{index + 1}</td> {/* S.No column */}
                <td className="py-4 px-6">{user.name}</td>
                <td className="py-4 px-6">{user.email}</td>
                <td className="py-4 px-6">{user.id}</td>
                <td className="py-4 px-6 text-green-600">{`₹${user.total_deposit_usdt}`}</td>
                <td className="py-4 px-6 text-red-600">{`₹${user.total_withdrawal_usdt}`}</td>
                <td className="py-4 px-6">
                  <button
                    onClick={() => confirmDeleteUser(user)}
                    className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {userToDelete && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
            <p>Do you really want to delete user {userToDelete.name}?</p>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => handleDeleteUser(userToDelete.id, userToDelete.name)}
                className="mr-2 text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Yes, Delete
              </button>
              <button
                onClick={cancelDeleteUser}
                className="text-white bg-gray-500 hover:bg-gray-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
