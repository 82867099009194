import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface UserReferralCode {
  uid: string;
  name: string;
  referral_code: string;
}

const UserReferralCodes: React.FC = () => {
  const [userCodes, setUserCodes] = useState<UserReferralCode[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchUserReferralCodes();
  }, []);

  const fetchUserReferralCodes = async () => {
    try {
      const response = await axios.get('https://api.time2win.in/api/user-referral-codes');
      setUserCodes(response.data);
    } catch (error) {
      console.error('Failed to fetch user referral codes:', error);
      setUserCodes([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredUserCodes = searchTerm
    ? userCodes.filter(user => 
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        user.referral_code.includes(searchTerm))
    : userCodes;

  return (
    <div>
      <div className="mb-2">
        <input
          type="text"
          placeholder="Search by Name or Referral Code"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-1 border rounded w-full"
        />
      </div>
      <div className="overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Referral Code</th>
            </tr>
          </thead>
          <tbody>
            {filteredUserCodes.map((user, index) => (
              <tr key={index}>
                <td className="py-1 px-2 border-b border-gray-200">{user.uid}</td>
                <td className="py-1 px-2 border-b border-gray-200">{user.name}</td>
                <td className="py-1 px-2 border-b border-gray-200">{user.referral_code}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserReferralCodes;
