import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface AdminUpi {
  upi_id: string;
}

const AdminUpiComponent: React.FC = () => {
  const [upiId, setUpiId] = useState<string>('');
  const [newUpiId, setNewUpiId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    fetchAdminUpi();
  }, []);

  const fetchAdminUpi = async () => {
    try {
      const response = await axios.get('https://api.time2win.in/api/admin/upi');
      setUpiId(response.data.upi_id);
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch admin UPI ID');
      setLoading(false);
    }
  };

  const handleUpdateUpi = async () => {
    try {
      await axios.put('https://api.time2win.in/api/admin/upi', { upi_id: newUpiId });
      setUpiId(newUpiId);
      setNewUpiId('');
      alert('Admin UPI ID updated successfully');
    } catch (err) {
      setError('Failed to update admin UPI ID');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2 className="text-xl font-semibold mb-4"></h2>
      {error && <div className="text-red-500 mb-4">{error}</div>}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Current UPI ID:</label>
        <div className="p-2 border rounded bg-gray-100">{upiId}</div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">New UPI ID:</label>
        <input
          type="text"
          value={newUpiId}
          onChange={(e) => setNewUpiId(e.target.value)}
          className="p-2 border rounded w-full"
          placeholder="Enter new UPI ID"
        />
      </div>
      <button
        onClick={handleUpdateUpi}
        className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Update UPI ID
      </button>
    </div>
  );
};

export default AdminUpiComponent;
