import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface ReferralReward {
  s_no: number;
  referrer_name: string;
  referred_name: string;
  income_date: string;
  amount: number;
}

const ReferralRewards: React.FC = () => {
  const [rewards, setRewards] = useState<ReferralReward[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchReferralRewards();
  }, []);

  const fetchReferralRewards = async () => {
    try {
      const response = await axios.get('https://api.time2win.in/api/referral-income-details');
      setRewards(response.data);
    } catch (error) {
      console.error('Failed to fetch referral rewards:', error);
      setRewards([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredRewards = searchTerm
    ? rewards.filter(reward => 
        reward.referrer_name.toLowerCase().includes(searchTerm.toLowerCase()) || 
        reward.referred_name.toLowerCase().includes(searchTerm.toLowerCase()))
    : rewards;

  return (
    <div>
      <div className="mb-2">
        <input
          type="text"
          placeholder="Search by Referrer or Referred Name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-1 border rounded w-full"
        />
      </div>
      <div className="overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Referrer Name</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Referred Name</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Income Date</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
            </tr>
          </thead>
          <tbody>
            {filteredRewards.map((reward, index) => (
              <tr key={index}>
                <td className="py-1 px-2 border-b border-gray-200">{reward.s_no}</td>
                <td className="py-1 px-2 border-b border-gray-200">{reward.referrer_name}</td>
                <td className="py-1 px-2 border-b border-gray-200">{reward.referred_name}</td>
                <td className="py-1 px-2 border-b border-gray-200">{reward.income_date}</td>
                <td className="py-1 px-2 border-b border-gray-200">{reward.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReferralRewards;
