import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Deposit {
  transaction_id: number;
  uid: string;
  date: string;
  txtid: string;
  amount: number;
  name: string;
  user_upi_id: string;
  admin_upi_id: string;
  status: string;
}

const Deposit: React.FC = () => {
  const [deposits, setDeposits] = useState<Deposit[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    fetchDepositData();
  }, []);

  const fetchDepositData = async () => {
    try {
      const response = await axios.get('https://api.time2win.in/api/deposits');
      const data = response.data;

      if (Array.isArray(data)) {
        const reversedData = data.reverse();
        setDeposits(reversedData);
      } else {
        console.error('Unexpected response structure:', data);
        setDeposits([]);
      }
    } catch (error) {
      console.error('Failed to fetch deposit data:', error);
      setDeposits([]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleUpdateStatus = async (transaction_id: number, status: string) => {
    try {
      await axios.put('https://api.time2win.in/api/deposit/status', { transaction_id, status });
      fetchDepositData();
    } catch (error) {
      console.error(`Failed to update deposit status for transaction_id ${transaction_id}:`, error);
    }
  };

  const filteredDepositData = searchTerm
    ? deposits.filter(deposit => 
        String(deposit.uid).includes(searchTerm) || 
        deposit.name.toLowerCase().includes(searchTerm.toLowerCase()))
    : deposits;

  return (
    <div>
      <div className="mb-2">
        <input
          type="text"
          placeholder="Search by UID or Name"
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-1 border rounded w-full"
        />
      </div>
      <div className="overflow-hidden">
        <table className="min-w-full table-fixed">
          <thead>
            <tr>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">S.No.</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">UID</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Name</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Date</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Transaction Id</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Amount</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">User UPI ID</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Admin UPI ID</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Status</th>
              <th className="py-1 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredDepositData.map((deposit, index) => (
              <tr key={deposit.transaction_id}>
                <td className="py-1 px-2 border-b border-gray-200">{index + 1}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.uid}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.name}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.date}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.txtid}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.amount}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.user_upi_id}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.admin_upi_id}</td>
                <td className="py-1 px-2 border-b border-gray-200">{deposit.status}</td>
                <td className="py-1 px-2 border-b border-gray-200">
                  {deposit.status === 'processing' && (
                    <>
                      <button
                        onClick={() => handleUpdateStatus(deposit.transaction_id, 'deposited')}
                        className="text-white bg-green-500 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                      >
                        Accept
                      </button>
                      <button
                        onClick={() => handleUpdateStatus(deposit.transaction_id, 'rejected')}
                        className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        Reject
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Deposit;
