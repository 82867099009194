import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Alert, AlertColor, Snackbar } from "@mui/material";

interface FormData {
  email: string;
  password: string;
}

interface FormErrors {
  email?: string;
  password?: string;
}
interface SnackbarState {
	open: boolean;
	message: string;
	severity: AlertColor; // Explicitly typing the severity as AlertColor
  }
  

	
const LoginPage = () => {
  const [formData, setFormData] = useState<FormData>({ email: "", password: "" });
  const [errors, setErrors] = useState<FormErrors>({});
  const [snackbar, setSnackbar] = useState<SnackbarState>({ severity: "success", message: "hello", open: false });
  const navigate = useNavigate();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({ ...prevFormData, [name]: value }));
  };

  const validateForm = (): boolean => {
    let valid = true;
    let errors: FormErrors = {};

    if (!formData.email) {
      valid = false;
      errors.email = "Email is required";
    }
    if (!formData.password) {
      valid = false;
      errors.password = "Password is required";
    }

    setErrors(errors);
    return valid;
  };

  const handleCloseSnackbar = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const loginClick = async () => {
    if (!validateForm()) {
      return; // Form validation failed, do not proceed with login
    }
    try {
      if(formData.email == 'admin@time2win.in' && formData.password == 'Admin@234')
      {
        localStorage.setItem('token','token');
        window.location.href = '/';
      }
      
    } catch (error: any) {
      // Error occurred during login
      const message = error.response?.data?.message || "Something went wrong..";
      setSnackbar({ severity: "error", message, open: true });
    }
  };
  

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loginClick();
  };

  return (
    <>
      <div className='flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-whit min-h-screen  text-white'>
	  <div className='absolute -z-10'>
				{/* <img
					src='https://images.pexels.com/photos/6802049/pexels-photo-6802049.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
					alt=''
					className='h-screen w-screen opacity-40'
				/> */}
			</div>
			<div className='max-w-md w-full space-y-4 bg-transparent shadow border border-gray-800 rounded-lg p-10'>
				<h2 className='text-center text-3xl font-extrabold text-gray-900'>
				   Login
				</h2>
        <form className='mt-8 space-y-4' onSubmit={handleSubmit}>
         {/* Email Field */}
					<div className='mb-2'>
						<input
							type='email'
							name='email'
							id='email'
							autoComplete='email'
							required
							className='appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
							placeholder='Email'
							value={formData.email}
							onChange={handleChange}
						/>
						{errors.email && (
							<p className='text-red-500 text-xs italic'>
								{errors.email}
							</p>
						)}
					</div>

					{/* Password Field */}
					<div className='mb-2'>
						<input
							type='password'
							name='password'
							id='password'
							autoComplete='new-password'
							required
							className='appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm'
							placeholder='Password'
							value={formData.password}
							onChange={handleChange}
							/>
						{errors.password && (
							<p className='text-red-500 text-xs italic'>
								{errors.password}
							</p>
						)}
					</div>
          <button
            type='submit'
            className='group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'>
            Login
          </button>
        </form>
		{/* Footer */}

				</div>
      </div>

      <Snackbar open={snackbar.open} autoHideDuration={2500} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginPage;
